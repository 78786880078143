import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  school: [],

  gallery: [],

  district: [],
  addschool: [],
  editschool: [],
  deleteschool: [],
};

const SchoolSlice = createSlice({
  name: "district",
  initialState,
  reducers: {
    SchoolRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.school = [];
    },
    SchoolSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.school = action.payload;
    },
    SchoolFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.school = [];
    },
    districtRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.district = [];
    },
    districtSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.district = action.payload;
    },
    districtFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.district = [];
    },

    addSchoolRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addschool = [];
    },
    addSchoolSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addschool = action.payload;
    },
    addSchoolFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addschool = [];
    },
    editSchoolRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editschool = [];
    },
    editSchoolSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editschool = action.payload;
    },
    editSchoolFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editschool = [];
    },
    deleteSchoolRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deleteschool = [];
    },
    deleteSchoolSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleteschool = action.payload;
    },
    deleteSchoolFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deleteschool = [];
    },

    galleryRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    gallerySuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    galleryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deletegalleryRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    deletegallerySuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    deletegalleryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    getgalleryRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.gallery = [];
    },
    getgallerySuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.gallery = action.payload;
    },
    getgalleryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.gallery = [];
    },
  },
});
export const SchoolAction = SchoolSlice.actions;
export default SchoolSlice;
