import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  recommend: [],
  onerecommend: [],
  addrecommend: [],
  editrecommend: [],
  deleterecommend: [],
};

const RecommendSlice = createSlice({
  name: "Recommend",
  initialState,
  reducers: {
    RecommendRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.recommend = [];
    },
    RecommendSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.recommend = action.payload;
    },
    RecommendFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.recommend = [];
    },
    onerecommendRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.onerecommend = [];
    },
    onerecommendSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.onerecommend = action.payload;
    },
    onerecommendFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.onerecommend = [];
    },
    addRecommendRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addrecommend = [];
    },
    addRecommendSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addrecommend = action.payload;
    },
    addRecommendFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addrecommend = [];
    },
    editRecommendRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editrecommend = [];
    },
    editRecommendSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editrecommend = action.payload;
    },
    editRecommendFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editrecommend = [];
    },
    deleteRecommendRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deleterecommend = [];
    },
    deleteRecommendSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deleterecommend = action.payload;
    },
    deleteRecommendFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deleterecommend = [];
    },
  },
});
export const RecommendAction = RecommendSlice.actions;
export default RecommendSlice;
