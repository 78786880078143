import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  level: [],
  addlevel: [],
  editlevel: [],
  deletelevel: [],
};

const LevelSlice = createSlice({
  name: "Level",
  initialState,
  reducers: {
    levelRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.level = [];
    },
    levelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.level = action.payload;
    },
    levelFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.level = [];
    },

    addlevelRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addlevel = [];
    },
    addlevelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addlevel = action.payload;
    },
    addlevelFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addlevel = [];
    },
    editlevelRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editlevel = [];
    },
    editlevelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editlevel = action.payload;
    },
    editlevelFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editlevel = [];
    },
    deletelevelRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deletelevel = [];
    },
    deletelevelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deletelevel = action.payload;
    },
    deletelevelFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deletelevel = [];
    },
    // signoutRequest: (state, action) => {
    //   state.loading = true;
    //   state.error = null;
    //   state.signoutt = [];
    // },
    // signoutSuccess: (state, action) => {
    //   state.loading = false;
    //   state.error = null;
    //   state.signoutt = action.payload;
    // },
    // signoutFailure: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    //   state.signoutt = [];
    // },
    // changepasswordRequest: (state, action) => {
    //   state.loading12 = true;
    //   state.error12 = null;
    //   state.changepassword = [];
    // },
    // changepasswordSuccess: (state, action) => {
    //   state.loading12 = false;
    //   state.error12 = null;
    //   state.changepassword = action.payload;
    // },
    // changepasswordFailure: (state, action) => {
    //   state.loading12 = false;
    //   state.error12 = action.payload;
    //   state.changepassword = [];
    // },
  },
});
export const LevelAction = LevelSlice.actions;
export default LevelSlice;
