import { combineReducers } from "redux";
//user
import LevelSlice from "./Level/LevelReducer";
import MajorSlice from "./Major/MajorReducer";
import MajorLevelSlice from "./LevelMajor/LevelMajorReducer";
import SchoolSlice from "./School/SchoolReducer";
import RecommendSlice from "./Recommend/RecommendReducer";
const rootReducer = combineReducers({
  LevelReducer: LevelSlice.reducer,

  MajorReducer: MajorSlice.reducer,
  LevelMajorReducer: MajorLevelSlice.reducer,
  SchoolReducer: SchoolSlice.reducer,
  RecommendReducer: RecommendSlice.reducer,
});

export default rootReducer;
