import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  majorLevel: [],
  addmajorLevel: [],
  editmajorLevel: [],
  deletemajorLevel: [],
};

const MajorLevelSlice = createSlice({
  name: "MajorLevel",
  initialState,
  reducers: {
    MajorLevelRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.majorLevel = [];
    },
    MajorLevelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.majorLevel = action.payload;
    },
    MajorLevelFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.majorLevel = [];
    },

    addMajorLevelRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addmajorLevel = [];
    },
    addMajorLevelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addmajorLevel = action.payload;
    },
    addMajorLevelFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addmajorLevel = [];
    },
    editMajorLevelRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editmajorLevel = [];
    },
    editMajorLevelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editmajorLevel = action.payload;
    },
    editMajorLevelFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editmajorLevel = [];
    },
    deleteMajorLevelRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deletemajorLevel = [];
    },
    deleteMajorLevelSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deletemajorLevel = action.payload;
    },
    deleteMajorLevelFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deletemajorLevel = [];
    },
  },
});
export const MajorLevelAction = MajorLevelSlice.actions;
export default MajorLevelSlice;
