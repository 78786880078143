import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  major: [],
  addmajor: [],
  editmajor: [],
  deletemajor: [],
};

const MajorSlice = createSlice({
  name: "Major",
  initialState,
  reducers: {
    MajorRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.major = [];
    },
    MajorSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.major = action.payload;
    },
    MajorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.major = [];
    },

    addMajorRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.addmajor = [];
    },
    addMajorSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.addmajor = action.payload;
    },
    addMajorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addmajor = [];
    },
    editMajorRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.editmajor = [];
    },
    editMajorSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.editmajor = action.payload;
    },
    editMajorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.editmajor = [];
    },
    deleteMajorRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.deletemajor = [];
    },
    deleteMajorSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.deletemajor = action.payload;
    },
    deleteMajorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.deletemajor = [];
    },
  },
});
export const MajorAction = MajorSlice.actions;
export default MajorSlice;
